<template>
  <div class="data-box">
    <van-nav-bar
        title="住宿安排"
        left-text="返回"
        right-text=""
        left-arrow
        fixed
        z-index="1000"
        placeholder
        safe-area-inset-top
        @click-left="onClickLeft"
    />
    <div class="data-box-head">
      活动期间请注意您的作息时间安排，并遵守酒店<br/>
      相关规定，配合酒店方的安排，离场时须完整交回
    </div>
    <div class="data-box-body">
      <div class="body-div">
        <div class="body-div-item">
          <span class="span-head">住宿安排负责人</span>
          <span class="span-name align-right">{{ personInfo.chargeName ? personInfo.chargeName : "无" }}</span>
          <a :href="'tel:' + personInfo.chargePhone" class="iconfont icon-dianhua1" v-show="personInfo.chargePhone"></a>
        </div>
      </div>
      <div class="body-div">
        <div class="body-div-item" v-for="(item,index) in liveKeyValueList" :key="index">
          <span class="span-key">{{ item.key }}</span>
          <span class="span-value" :style="{width: item.hasIcon ? '60%' : '',}">{{ item.value ? item.value : "无" }}</span>
          <a :href="'tel:' + item.value" class="iconfont icon-dianhua1" v-show="item.hasIcon&&item.value"></a>
        </div>
      </div>
      <div class="body-div">
        <div class="body-div-code">
          <vue-qr :text="codeUrl" :size="200"></vue-qr>
        </div>
        <div class="body-div-prompt align-center">
          入住与离店时请向酒店工作人员展示二维码<br/>
          进行入住与退房手续
        </div>
      </div>
    </div>
    <van-overlay :show="$store.state.showOverlay" :z-index="1010">
      <van-loading class="loading-box" type="spinner" color="#1989fa"/>
    </van-overlay>
  </div>
</template>

<script>
import vueQr from 'vue-qr';
import {
  addActivityObjectCanSelectHotelRoom,
  participantStay,
  stayArrangeShow,
  getWxCode
} from "@/api/index";

export default {
  name: "parLive",
  data() {
    return {
      personInfo: {},
      liveKeyValueList: [
        { key: "入住酒店", value: "", hasIcon: false},
        { key: "入住房型", value: "", hasIcon: false},
        { key: "安排房号", value: "", hasIcon: false},
        { key: "住宿日期", value: "", hasIcon: false},
        { key: "同房人员", value: "", phone: "", hasIcon: true}
      ],
      codeUrl: ""
    }
  },
  components: {
    vueQr
  },
  mounted() {
    let that = this;
    that.initData();
  },
  methods:{
    initData(){
      let that = this;
      let activitySets = that.$store.getters.getOPDetails.activitySets;
      activitySets.forEach(item => {
        if(item.relateType === 2){
          that.personInfo = item;
          that.liveKeyValueList[0].value = "";
          that.liveKeyValueList[1].value = item.checkTypeNumArticle;
          that.liveKeyValueList[2].value = "";
          that.liveKeyValueList[3].value = item.noteNameDateStop;
          that.liveKeyValueList[4].value = "";
          that.getHotelRoom();
        }
      })
      that.getLiveInfo();
      that.getWxCode();
      that.getStayArrangeShow()
    },
    getStayArrangeShow() {
      let that = this
      let params = {
        ayId: that.$store.getters.getOPDetails.id, // 人员ID
        companyId: that.$store.getters.getCompanyId // 公司ID
      }
      stayArrangeShow(params).then((res) => {
        that.liveKeyValueList[2].value = res.data.aoscsList[0].roomEnergyFood;

        // 同房人员,如果有多个时处理
        res.data.togetherManInfo.forEach((item,index,arr) => {
          let arr2 = item.split(",")
          if(index >= 1) {
            that.liveKeyValueList.splice((4+index),0,{
              key: "同房人员",
              value: arr2[0],
              phone: arr2[1],
              hasIcon: true
            })
          }else {
            that.liveKeyValueList[4].value = arr2[0];
            that.liveKeyValueList[4].phone = arr2[1]
          }
        })

      })
    },
    getLiveInfo(){
      let that = this;
      let params = {
        ayId: that.$store.getters.getOPDetails.id
      }
      participantStay(params).then(res => {
        that.$tool.resValidation(res, () => {

        })
      })
    },
    getHotelRoom(){
      let that = this;
      let date = that.liveKeyValueList[3].value.split("~");
      let params = {
        "actId": that.$store.getters.getOPDetails.activityId,
        "startDate": date ? date[0] : "",
        "endDate": date ? date[1] : ""
      };
      addActivityObjectCanSelectHotelRoom(params).then(res => {
        that.$tool.resValidation(res, () => {
          let hotelList = res.data;
          hotelList.forEach(item => {
            if(that.personInfo.chargeTypeId == item.id){
              that.liveKeyValueList[0].value = item.str;
            }
          })
        })
      })
    },
    getWxCode(){
      let that = this;
      that.codeUrl = that.$store.state.baseURL + "#/parLive_details?id=" + that.$store.getters.getActObjId;
      /*let url = that.$store.state.baseURL + "#/parLive_details"
      let params ={
        app_id: that.$store.state.app_id,
        redirect_uri: that.$tool.toURLEncode(url),
        state: 1
      };
      that.codeUrl = getWxCode(params);*/
    },
    onClickLeft() {
      this.$router.go(-1);
    }
  }
}
</script>

<style lang="scss" scoped>
 @import "@/assets/css/common.scss";
</style>